<script setup>
let props = defineProps({
  meta: {
    required: true,
    type: Object,
    default: () => ({}),
  },
});

let emit = defineEmits(["setCurrentChange"]);

let currentChange = (e) => {
  emit("setCurrentChange", e);
};
</script>

<template>
  <div class="pagination">
    <el-pagination
      background
      layout="prev, pager, next"
      :total="meta.total"
      :page-size="Number(meta.per_page)"
      @current-change="currentChange"
      :hide-on-single-page="true"
    />
  </div>
</template>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
::deep(.el-pagination) {
  text-align: center;
}
</style>
