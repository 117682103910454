<script setup>
import { computed } from "vue";

let props = defineProps({
  bilingArr: {
    type: Array,
    default: () => [],
  },
  typeArr: {
    type: Object,
    default: () => ({}),
  },
});

//类型
let setType = computed(() => {
  return (e) => {
    return props.typeArr[e];
  };
});

//设置时间
let setTime = computed(()=>{
  return (e) => {
    return e.split(' ')
  }
})
</script>

<template>
  <div class="table_box">
    <el-table ref="multipleTableRef" :data="bilingArr" style="width: 100%">
      <el-table-column property="number" label="Payment" min-width="180" />

      <el-table-column
        property="description"
        label="Description"
        min-width="250"
      />
      <el-table-column
        property="order_numbers"
        label="Number"
        min-width="180"
      />
      <el-table-column property="type" label="Type" min-width="90">
        <template #default="{ row }">{{ setType(row.type) }}</template>
      </el-table-column>
      <el-table-column
        property="amount"
        label="Amount"
        min-width="100"
        align="center"
      />
      <el-table-column
        property="pay_code"
        label="Payment Method"
        min-width="140"
        align="center"
      />
      <el-table-column property="updated_at" label="Date" min-width="100" >
        <template #default="{row}">
          <div v-for="(item,index) in setTime(row.updated_at)" :key="index">
              {{item}}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped lang="scss"></style>
