<script setup>
import { defineComponent, reactive, toRefs } from "vue";

const props = defineProps({
    // 数据
    list:{
        required: true,
        type:Array,
        default:()=>([])

    },
    // 显示条件
    index: {
        required:true,
        type:Number
    }
})

//回到顶部
let toTop = () => {
  let top = document.documentElement.scrollTop || document.body.scrollTop;
  // 实现滚动效果
  const timeTop = setInterval(() => {
    document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
};

</script>

<template>
  <div class="backTop" @click="toTop" v-if="list.length > index">
    Back up to top
    <el-icon style="line-height: 35px; margin-left: 10px" :size="20"
      ><top
    /></el-icon>
  </div>
</template>

<style scoped lang="scss">
.backTop {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 50px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 22px;
  margin-bottom: 20px;
}
</style>
