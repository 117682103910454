import request from "@/utils/request.js";

// 当前申请状态
export function typeList() {
  return request({
    url: "/wallet/typeList",
    method: "get",
  });
}

//账单列表

export function bilingList({
  page,
  type,
  endTime,
  startTime, //时间搓/秒
  orderNumber,
}) {
  return request({
    url: "/wallet/list",
    method: "get",
    params: {
      page: page || 1,
      type: type || undefined,
      endTime: endTime ? endTime / 1000 :  undefined, //时间搓/秒
      startTime: startTime ? startTime / 1000 : undefined, //时间搓/秒
      orderNumber: orderNumber || undefined,
    },
  });
}

//下载exce
export function exportUrl(
    { 
        type,
        endTime,
        startTime, //时间搓/秒 
        orderNumber,
        extension
    }
    ) {
  return request({
    url: "/wallet/exportUrl",
    method: "get",
    params: {
        type: type || undefined,
        endTime: endTime ? endTime / 1000 :  undefined, //时间搓/秒
        startTime: startTime ? startTime / 1000 : undefined, //时间搓/秒
        orderNumber: orderNumber || undefined,
        extension
      },
  });
}
