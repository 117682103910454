<script setup>
import { reactive, onMounted, ref } from "vue";
import TopSearch from "./components/topSearch.vue";
import BillingTable from "./components/billingTable.vue";
import CopyrightInfo from "@/components/copyrightInfo.vue";
import Pagination from "@/components/pagination.vue";
import BackTo from "@/components/backTo.vue";
import { ElMessage } from "element-plus";
import { bilingList, typeList, exportUrl } from "@/api/biling";

//搜索

let form = reactive({
  keyword: "",
  types: "",
  times: [],
  page: 1,
});

//搜索 下拉框 时间
let search = (key, val) => {
  for (let index in form) {
    if (index === key) {
      form[index] = val;
    }
  }

  form.page = 1;
  getBilingList();
};

//分页
let setCurrentChange = (e) => {
  form.page = e;
  getBilingList();
};

//列表
onMounted(() => {
  getBilingList();
});
let bilingArr = ref([]);
let meta = ref({});
let loading = ref(false);
let getBilingList = async () => {
  loading.value = true;
  try {
    const { data } = await bilingList({
      page: form.page,
      type: form.types,
      endTime: !!form.times ? form.times[1] : undefined,
      startTime: !!form.times ? form.times[0] : undefined, //时间搓/秒
      orderNumber: form.keyword,
    });
    bilingArr.value = data?.data;
    meta.value = data?.meta;
  } catch (error) {
    ElMessage.error(error);
  }
  loading.value = false;
};



//获取类型列表
let typeArr = ref([]);
onMounted(() => {
  getTypeList();
});

let getTypeList = async () => {
  try {
    const { data } = await typeList();

    typeArr.value = data;
  } catch (error) {
    ElMessage.error(error);
  }
};

//文件下载
let downloadFile =  (e) => {

    //接口调用下载
    apiDownload(e);
};

let apiDownload = async (extension) => {
  try {
    const {data} = await exportUrl({
      type: form.types,
      endTime: !!form.times ? form.times[1] : undefined,
      startTime: !!form.times ? form.times[0] : undefined, //时间搓/秒
      orderNumber: form.keyword,
      extension
    });
    window.location.href = data?.info
  } catch (error) {
    ElMessage.error(error);
  }
};
</script>

<template>
  <div class="main">
    <div class="w1120">
      <TopSearch
        @search="search"
        :typeArr="typeArr"
        @downloadFile="downloadFile"
      />
      <div class="table_box" v-loading="loading">
        <BillingTable
          :bilingArr="bilingArr"
          :typeArr="typeArr"
        />
        <Pagination @setCurrentChange="setCurrentChange" :meta="meta" />
        <BackTo :list="bilingArr" :index="8" />
      </div>
      <CopyrightInfo />
    </div>
  </div>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  justify-content: center;
  .table_box {
    width: 100%;
    background-color: #fff;
    padding: 10px;
  }
}
</style>
