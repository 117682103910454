<script setup>
import { reactive, onMounted, ref } from "vue";
import { Search } from "@element-plus/icons";

const emit = defineEmits(["search","downloadFile"]);

const props = defineProps({
  typeArr:{
    type:Object,
    default:()=>({})
  }
})



let formInline = reactive({
  keyword: "",
  types: [],
  times: "",
});

let download1 = (e) => {
  emit('downloadFile',e)
};

// 转成时间戳
let handleClick = (e) => {
  var date = new Date(e);
  return date.getTime(); //精确到毫秒
};
let keywordChange = (key, val) => {
  if (key === "times" && !!val) {
    //转成时间搓
    formInline.times[0] = handleClick(val[0]); //开始时间
    formInline.times[1] = handleClick(val[1]); //结束时间
  } 
     emit("search", key, val);
};
</script>

<template>
  <h1 class="title">Billing: Payment</h1>
  <el-form :inline="true" :model="formInline" class="demo-form-inline">
    <el-row :gutter="20" style="width: 100%">
      <el-col :span="7">
        <el-form-item>
          <el-input
            v-model="formInline.keyword"
            placeholder="Search"
            clearable
            :prefix-icon="Search"
            @change="keywordChange('keyword', $event)"
          /> </el-form-item
      ></el-col>
      <el-col :span="7">
        <el-form-item>
          <el-select
            v-model="formInline.types"
            placeholder="Select"
            clearable
            @change="keywordChange('types', $event)"
          >
            <el-option label="All types" value="all" />
            <el-option
              :label="key"
              :value="val"
              v-for="(key, val, i) in typeArr"
              :key="i"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item>
          <el-date-picker
            v-model="formInline.times"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="keywordChange('times', $event)"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div class="flex download">
    <div>Download report：</div>
    <div class="vsc" @click="download1('csv')">
      <i class="icon-xiazai iconfont"></i>
      <span>CSV</span>
    </div>
    <div class="vsc" @click="download1('xlsx')">
      <i class="icon-xiazai iconfont"></i>
      <span>XLSX</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon-xiazai {
  margin-right: 5px;
}
.title {
  font-size: 36px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 50px;
  margin-top: 50px;
  margin-bottom: 30px;
}
:deep(.el-form-item),
:deep(.el-range-editor),
:deep(.el-select) {
  width: 100%;
}
.download {
  line-height: 22px;
  margin-bottom: 20px;
  .vsc {
    margin: 0 20px;
    line-height: 25px;
    &:hover {
      color: #1eab7f;
      user-select: none;
      cursor: pointer;
    }
  }
}
</style>
